.drop-file-input {
  position: relative;
  outline: none;
}

.drag {
  font-family: 'Arial', Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* or 18px */

  display: block;
  text-align: left;
  letter-spacing: -0.011em;

  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 10px;
}

.dragError {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #707070;
  padding: 23px 56px;
}

.drag span {
  font-weight: 400;
  color: #661c69;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
}

.dragError span {
  font-weight: 400;
  color: #661c69;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
}

.fileName {
  color: #661c69;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.disabledFileField {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.activeButton {
  cursor: pointer;
}

.progressRemoveWrapper {
  margin-right: 9px !important;
}

.validation {
  font-family: 'Arial', Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  /* or 15px */

  display: block;
  text-align: center;
  letter-spacing: -0.011em;

  /* Gray/Cool Gray */

  color: #727578;
  margin-top: 10px;
}

.dropFileInputLabel_inner {
  width: 100%;
}

.validationError {
  font-weight: 500;
  font-size: 12px;
  color: #d71721;
}
#selectFiles {
  font-family: 'Arial', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #661c69;
  background-color: #fff;
  border: 1px solid #661c69;
  box-shadow: none;
  border-radius: 50px;
  outline: none;
  width: 100%;
}

.noteBox {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.dropFileInputLabel {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: relative;
}

.dropFileInputLabelError {
  width: 100%;
  text-align: center;
  color: var(--txt-second-color);
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: relative;
}

.dropFileInputLabel:hover {
  border: none;
}

.drop-file-preview p {
  font-weight: 500;
}

.drop-file-preview__title {
  margin-bottom: 20px;
}

.drop-file-preview__item {
  position: relative;
  display: flex;
  background-color: var(--input-bg);
  padding: 15px 10px 15px 10px;
  border-radius: 20px;
}

.drop-file-preview__item img {
  width: 20px;
  height: 20px;
}

.drop-file-preview__item__info {
  word-break: break-all;
  white-space: normal;
}

.removeButton {
  position: relative;
  text-align: right;
  padding-right: 5px;
}
.removeButton:hover {
  opacity: 1;
  cursor: pointer;
}

.fileUploadContainer {
  border: 0.6px solid #565759;
  background: #ffffff;
  border-radius: 12px;
  padding: 10px;
}

.fileListWrapper {
  border: 0.6px solid #565759;
  border-radius: 12px 12px 12px 12px;
  font-size: 12px;
  line-height: 18px;
  margin-top: 15px;
  padding: 0 10px;
}

.pointer:hover {
  cursor: pointer;
}

.undoAllWrapper {
  border: 0.6px solid #565759;
  border-radius: 12px 12px 12px 12px;
  padding: 0 10px;
  font-family: 'Arial', Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 15px;
  align-items: center;
}

.buttonsWrapper {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

button.actionButtons {
  border: 1.6px solid #661c69;
  border-radius: 56px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #661c69;
  text-transform: capitalize;
}

button.containedActionButton {
  border: 1.6px solid #661c69;
  border-radius: 56px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  background-color: #661c69;
  color: #fff;
  text-transform: capitalize;
}
button.containedActionButton:hover {
  color: #661c69;
  background-color: #fff;
}

.progressBarWrapper {
  margin-top: 15px;
}

.svg {
  padding-top: 10px;
}

.progressCloseSvg {
  padding-bottom: 10px;
}

.infoIconWrapper {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .dropFileInputLabel {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
}
